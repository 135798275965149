import React from "react"

import Layout from "../components/layout"
import Landing from "../components/workingSections/workingLanding"
import Questions from "../components/workingSections/workingQuestions"
import Product from "../components/workingSections/workingProduct"

const WorkingPage = () => (
  <Layout>
    <Landing />
    <Questions />
    <Product />
  </Layout>
)

export default WorkingPage
