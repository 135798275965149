import React from "react"

// import Product from "../../assets/images/vectors/product1.svg"
import Product from "../../assets/images/vectors/product.png"

import Product2 from "../../assets/images/vectors/product2.svg"

export default function WorkingProduct() {
  return (
    <div className="working-product">
      <img
        style={{width:"100%",padding:"10px"}}
        src={Product}
        alt="product image"
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-duration="700"
      />
      <img
      style={{width:"100%",padding:"10px"}}
        src={Product2}
        alt="product image"
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-duration="700"
      />
      <div className="working-product__content-container">
        <div className="u-margin-top-small"></div>
        <div className="working-product__content text-white">
          Some of our other features include:
        </div>
        <div className="u-margin-top-small"></div>

        <div className="working-product__content text-white">
          In-house drive to Store documents | Access to hearing rooms & Whatsapp
          integration | Digital Signatures | Transcription services
        </div>
        <div className="u-margin-top-small"></div>

        <div className="working-product__content text-white">
          We take security seriously. Webnyay is built on secure infrastructure
          that ensures your data is protected. We enforce a comprehensive set of
          policies that proactively mitigate risks and build a culture of
          security. If there are any issues, please report to admin@webnyay.in
        </div>
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
